<template>
  <div>
    <div class="lg:p-5 flex align-start p-5">
      <span class="my-auto">Hasta</span>

      <input
        v-model="weekDateTo"
        type="text"
        class="
          mx-5
          relative
          p-2
          bg-white
          rounded
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          focus:outline-none
          px-4
        "
        readonly
      />
      <!--
      <input
        v-model="weekDateFrom"
        min="1970-01-05"
        step="7"
        type="date"
        class="
          mr-2
          relative
          p-2
          bg-white
          rounded
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          focus:outline-none
          px-4
        "
      />

      <input
        v-model="weekDateTo"
        :min="weekDateFrom || '1970-01-05'"
        step="7"
        type="date"
        class="
          mx-5
          relative
          p-2
          bg-white
          rounded
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          focus:outline-none
          px-4
        "
      />
      -->
    </div>

    <div>
      <VueHighcharts
        v-if="showChart"
        type="chart"
        :redrawOnUpdate="true"
        :oneToOneUpdate="true"
        :animateOnUpdate="true"
        :options="chartOptions"
      />
    </div>

    <div
      v-if="
        [
          '5829c862-a149-4a03-9682-bb0e27a6b46f',
          'c64fb811-f3f6-4160-8a34-9adb1ffdb3d6',
        ].includes(getWork.key)
      "
      class="mt-10"
    >
      <div>
        <ChartPieCompliancePercentageByDeparture
          date-from="2021-01-01"
          :date-to="weekDateTo"
        />
      </div>
      <div class="mt-10">
        <ChartPieCompliancePercentageByUser
          date-from="2021-01-01"
          :date-to="weekDateTo"
        />
      </div>
    </div>
    <div v-else class="mt-10">
      <ChartPieCompliancePercentage
        date-from="2021-01-01"
        :date-to="weekDateTo"
      />
    </div>

    <div class="mt-10">
      <ChartPieCauseSubcontractPercentage
        date-from="2021-01-01"
        :date-to="weekDateTo"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from "vue";
import { useStore } from "vuex";
import VueHighcharts from "vue3-highcharts";
import dayjs from "dayjs";
import ChartPieCompliancePercentage from "../components/ChartPieCompliancePercentage";
import ChartPieCauseSubcontractPercentage from "../components/ChartPieCauseSubcontractPercentage";
import ChartPieCompliancePercentageByDeparture from "../components/ChartPieCompliancePercentageByDeparture";
import ChartPieCompliancePercentageByUser from "../components/ChartPieCompliancePercentageByUser";

const store = useStore();

const weekDateFrom = ref("");
const weekDateTo = ref("");
const loadingData = ref(false);
const chartData = ref([]);
const chartDataCategories = ref([]);
const showChart = ref(false);
const minPPC = ref(0);

const getWork = computed(() => store.getters.getWork);
const getPlanningPPCReport = computed(() => store.getters.getPlanningPPCReport);
const getChartColors = computed(() => store.getters.getChartColors);
const chartOptions = computed(() => ({
  chart: { type: "line", height: 580 },
  title: { text: "PPC SEMANAL ACUMULADO" },
  xAxis: { categories: chartDataCategories.value },
  yAxis: { title: { text: "PPC (%)" }, min: minPPC.value, max: 100 },
  credits: { enabled: false },
  series: chartData.value,
  tooltip: {
    headerFormat: "<b>{point.x}</b><br>",
    pointFormat: "{series.name}: {point.y:.2f} %",
  },
  colors: getChartColors.value,
}));

watch(
  () => getPlanningPPCReport.value,
  (currentValue) => {
    let categories = [];
    let data = [];
    let dataAccumulated = [];
    let ppc = 100;

    if (currentValue && currentValue.length) {
      for (let value of currentValue) {
        categories.push(value.initialDate);
        data.push(value.ppc);
        dataAccumulated.push(value.ppcAccumulated);

        if (value.ppc < ppc) ppc = value.ppc;
        if (value.ppcAccumulated < ppc) ppc = value.ppcAccumulated;
      }
    }

    minPPC.value = ppc;

    chartData.value = [
      {
        name: `PPC del ${
          categories.length ? categories[0] : weekDateFrom.value
        } al ${weekDateTo.value}`,
        data: data,
        dataLabels: {
          enabled: true,
          formatter: function () {
            // Show data labels for the last two points
            if (this.point.x >= this.series.xData[this.series.xData.length - 1])
              return `${this.y.toFixed(2)}%`;

            return null; // Hide data label for other points
          },
        },
      },
      {
        name: `PPC acumulado del ${
          categories.length ? categories[0] : weekDateFrom.value
        } al ${weekDateTo.value}`,
        data: dataAccumulated,
        dataLabels: {
          enabled: true,
          formatter: function () {
            // Show data labels for the last two points
            if (this.point.x >= this.series.xData[this.series.xData.length - 1])
              return `${this.y.toFixed(2)}%`;

            return null; // Hide data label for other points
          },
        },
      },
    ];
    chartDataCategories.value = categories;

    showChart.value = false;
    nextTick(() => {
      showChart.value = true;
    });
  },
  { deep: true }
);

const loadData = async () => {
  try {
    await store.dispatch("resetPlanningPPCReport");

    chartData.value = [];
    loadingData.value = true;

    await store.dispatch("loadPlanningPPCReport", {
      weekDateFrom: weekDateFrom.value,
      weekDateTo: weekDateTo.value,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingData.value = true;
  }
};

watch(
  () => weekDateFrom.value,
  () => loadData()
);
watch(
  () => weekDateTo.value,
  () => loadData()
);

const init = () => {
  weekDateFrom.value = "2021-01-01";
  weekDateTo.value = dayjs(new Date()).format("YYYY-MM-DD");
};

init();
</script>
