<template>
  <VueHighcharts
    v-if="chartData && chartData.length && !refreshChart"
    type="chart"
    :redrawOnUpdate="true"
    :oneToOneUpdate="false"
    :animateOnUpdate="true"
    :options="chartOptions"
  />
</template>

<script setup>
import { computed, defineProps, ref, watch, nextTick } from "vue";
import VueHighcharts from "vue3-highcharts";
import { useStore } from "vuex";

const props = defineProps({
  dateFrom: {
    type: String,
  },
  dateTo: {
    type: String,
  },
});

const store = useStore();

const chartData = ref([]);
const loadingData = ref(false);
const refreshChart = ref(false);

const getChartColors = computed(() => store.getters.getChartColors);
const getPlanningCauseByUserReport = computed(
  () => store.getters.getPlanningCauseByUserReport
);

const chartOptions = computed(() => ({
  chart: {
    type: "pie",
    options3d: {
      enabled: true,
      alpha: 45,
    },
    height: 600,
  },
  title: {
    text: "CAUSAS DE NO CUMPLIMIENTO POR RESPONSABLE",
  },
  colors: getChartColors.value,
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
      dataLabels: {
        enabled: true,
        format:
          '<span style="font-size:20px"><b>{point.name}</b>: {point.y:.0f}</span>',
      },
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:12px">{series.name}</span><br>',
    pointFormat: "<span>{point.name}</span>: <b>{point.y:.0f}</b><br/>",
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: "Cantdad de actividades",
      data: chartData.value,
    },
  ],
}));

const getShowMenu = computed(() => store.getters.getShowMenu);

watch(
  () => getShowMenu.value,
  () => {
    refreshChart.value = true;
    nextTick(() => {
      refreshChart.value = false;
    });
  }
);

watch(
  () => getPlanningCauseByUserReport.value,
  (currentValue) => {
    chartData.value =
      currentValue?.map((v) => ({
        name: `${v.name}`,
        y: v.userWorked,
        z: 10,
      })) || [];
  },
  { deep: true }
);

watch(
  () => props.dateFrom,
  () => loadData()
);

const loadData = async () => {
  try {
    store.dispatch("resetPlanningCauseByUserReport");

    chartData.value = [];
    loadingData.value = true;

    await store.dispatch("loadPlanningCauseByUserReport", {
      weekDateFrom: props.dateFrom,
      weekDateTo: props.dateTo,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingData.value = true;
  }
};

const init = async () => {
  await loadData();
};

init();
</script>
