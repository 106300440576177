<template>
  <div>
    <div class="lg:p-5 flex align-start p-5">
      <input
        v-model="date"
        min="1970-01-05"
        step="7"
        type="date"
        class="
          relative
          p-2
          bg-white
          rounded
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          focus:outline-none
          px-4
        "
      />
      <!-- <button
        class="
          flex
          text-white
          border
          text-sm
          font-medium
          bg-primary
          border-primary
          py-2
          ml-4
          px-3
          my-auto
        "
        :disabled="loadingExport"
        @click="onExportWeeklyPlanningReport"
      >
        <LoadingButtonIcon v-if="loadingExport" />
        Exportar
      </button> -->
    </div>
    <div
      v-if="
        [
          '5829c862-a149-4a03-9682-bb0e27a6b46f',
          'c64fb811-f3f6-4160-8a34-9adb1ffdb3d6',
        ].includes(getWork.key)
      "
      class="mt-10"
    >
      <div>
        <ChartPieCompliancePercentageByDeparture :date-from="date" />
      </div>
      <div class="mt-10">
        <ChartPieCompliancePercentageByUser :date-from="date" />
      </div>
    </div>
    <div v-else class="mt-10">
      <ChartPieCompliancePercentage :date-from="date" />
    </div>
    <div class="mt-10">
      <ChartPieCauseSubcontractPercentage :date-from="date" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import ChartPieCompliancePercentage from "../components/ChartPieCompliancePercentage";
import ChartPieCompliancePercentageByDeparture from "../components/ChartPieCompliancePercentageByDeparture";
import ChartPieCompliancePercentageByUser from "../components/ChartPieCompliancePercentageByUser";
import ChartPieCauseSubcontractPercentage from "../components/ChartPieCauseSubcontractPercentage";

const store = useStore();

const date = ref("");
const chartData = ref([]);
// const loadingExport = ref(false);

const getWork = computed(() => store.getters.getWork);
const getPlanningWeeklyReport = computed(
  () => store.getters.getPlanningWeeklyReport
);
const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);

watch(
  () => getPlanningWeeklyReport.value,
  (currentValue) => {
    chartData.value =
      currentValue?.map((v) => ({
        name: `${v.name} ${v.percentage}%`,
        y: v.percentage,
        z: 10,
      })) || [];
  },
  { deep: true }
);

const init = () => {
  date.value = getWeekInitialDate.value;
};

init();
/*
const getDay = (incrementDays) => {
  return dayjs(new Date(date.value))
    .add(incrementDays, "d")
    .format("YYYY-MM-DD");
};

const onExportWeeklyPlanningReport = async () => {
  try {
    loadingExport.value = true;

    const dateTo = getDay(6);

    const reportUrl = await store.dispatch("generateWeeklyPlanningReport", {
      dateFrom: date.value,
      dateTo: dateTo,
    });

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `planificacion-semanal.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  } finally {
    loadingExport.value = false;
  }
};
*/
</script>
