<template>
  <div class="min-h-full overflow-x-scroll">
    <nav
      class="tabs flex flex-row pt-5 border-b px-6 fixed bg-white w-full z-10"
    >
      <button
        class="
          tab
          active
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        :class="
          mode === 'weekly'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'weekly'"
      >
        Semanal
      </button>
      <button
        class="
          tab
          active
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        :class="
          mode === 'accumulative'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'accumulative'"
      >
        Acumulativo
      </button>
    </nav>

    <div v-if="getWeekInitialDate" class="p-4 xl:w-full lg:w-screen pt-20">
      <div v-if="mode === 'weekly'">
        <Weekly />
      </div>

      <div v-if="mode === 'accumulative'">
        <Accumulative />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Weekly from "./weekly/Weekly";
import Accumulative from "./accumulative/Accumulative";

const store = useStore();

const mode = ref("weekly");

const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);

const init = async () => {
  if (!getWeekInitialDate.value)
    await store.dispatch("setCurrentWeekInitialDate");
};

init();
</script>
